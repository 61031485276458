import anime from 'animejs/lib/anime.es.js';
export function headerFunc() {

}


export function subNaviFunc() {
  let resizecheck;
  resizecheck = $('#responsibleCheck').width();


  //メニュ開ける
  function openNavi() {
    anime({
      targets: '#header',
      translateY: {
        value: ['-100%', '0'],
        duration: 1000,
        easing: 'easeInOutExpo',
      },
      complete: function () {}
    });
  }

  //メニューを閉じる
  function closeNavi() {
    anime({
      targets: '#header',
      translateY: {
        value: ['0', '-100%'],
        duration: 1000,
        easing: 'easeInOutExpo',
      },
      complete: function () {}
    });
  }

  let header, spNavBtn, Scrollpos;
  header = $('#header');
  spNavBtn = $('#spNavBtn');

  spNavBtn.on('click', function () {
    $(this).toggleClass('active');
    header.toggleClass('open');
    if ($('#header').hasClass('open')) {
      openNavi();
      Scrollpos = $(window).scrollTop();
      $('body').addClass('fixedBody').css({
        'top': -Scrollpos
      });
    } else {
      closeNavi();
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, Scrollpos);
    }
  });
}